export const useScript = (url, ref) => {
    const existingScript = document.getElementById("bookeo-script");
    if (existingScript) return;

    const script = document.createElement("script");
    script.id = "bookeo-script";
    script.src = url;
    script.async = true;
    ref.current.appendChild(script);

    return () => {
        ref.current.removeChild(script);
    };
};

export const getScript = (type, store) => {
    if (type === "booking") {
        return store.booking_widgets_src;
    } else if (type === "gift_vouchers") {
        return store.gift_vouchers_widgets_src;
    } else if (type === "redeem_vouchers") {
        return store.redeem_vouchers_widgets_src;
    }
};
