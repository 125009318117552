import "./room.css";
import { lazy, Suspense } from "react";
const BetaVersion = lazy(() => import("Views/BetaVersion"));
const HomeCarousel = lazy(() => import("components/HomeCarousel/HomeCarousel"));
const Description = lazy(() =>
    import("components/Rooms/Description/Description")
);
const Location = lazy(() => import("components/Rooms/Location/Location"));
const BookNowButton = lazy(() =>
    import("components/Rooms/BookNowButton/BookNowButton")
);

export const Room = () => {
    return (
        <Suspense>
            <BetaVersion>
                <HomeCarousel type='rooms' />
                <section
                    className='text-center row col'
                    id='room-section'>
                    <Description />
                    <Location />
                </section>
                <BookNowButton />
            </BetaVersion>
        </Suspense>
    );
};

export default Room;
