//Don't change the functionality of this component
import { defaultLayout } from "helpers/Navigation.helpers";
import { useEffect, useState } from "react";
import { anchors, closeVerticalMenu } from "helpers/Navigation.helpers";
import { ENavLayouts } from "constants/Navigation";
import { useTranslation } from "react-i18next";
import Links from "../NavigationLinks";
import { Link } from "react-router-dom";
import "./index.css";
import config from "main-config.json";
import MobileLangDropdown from "../LanguageDropdown/MobileDropdown";

/**
 * @returns the Vertical navigation menu
 */

const VerticalNavigation = () => {
    const { navpanel } = config.runtime.pages.components;
    const { t } = useTranslation();

    const [scroll, setScroll] = useState(0);

    const incrementScroll = () => {
        return (
            document.documentElement &&
            setScroll(document.documentElement.scrollTop)
        );
    };
    useEffect(() => {
        window.addEventListener("scroll", incrementScroll);
        const navLinks = document.querySelectorAll(".nav-link");
        navLinks.forEach((link) => {
            link.addEventListener("click", closeVerticalMenu);
        });

        return () => {
            window.removeEventListener("scroll", incrementScroll);
            navLinks.forEach((link) => {
                link.removeEventListener("click", closeVerticalMenu);
            });
        };
    }, []);

    if (defaultLayout == ENavLayouts.HORIZONTAL) {
        return (
            <>
                <div
                    className='navbar-collapse collapse'
                    id='navbarResponsive'>
                    <Links />
                </div>
            </>
        );
    } else if (defaultLayout == ENavLayouts.VERTICAL) {
        return (
            <>
                <input
                    id='menu-toggle'
                    type='checkbox'
                />
                <label
                    className='menu-button-container navbar-toggler-right'
                    htmlFor='menu-toggle'
                    id='menu_btn'>
                    <div
                        className='menu-button'
                        id='menu_btn'></div>
                </label>
                <ul
                    style={{
                        backgroundColor:
                            scroll > 50 && innerWidth > 992
                                ? "black"
                                : `${
                                      innerWidth > 992 ? "transparent" : "black"
                                  }`,
                    }}
                    className='menu'
                    id='navbarResponsive'>
                    {/* Links */}
                    {navpanel.links.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className='nav-item'
                                id='nav-item'>
                                <Link
                                    className='nav-link js-scroll-trigger'
                                    to={item.link}
                                    onClick={() => {
                                        anchors(item.link);
                                    }}>
                                    {t(item.name)}
                                </Link>
                            </li>
                        );
                    })}
                    {config.supported_langs.length > 1 && (
                        <MobileLangDropdown />
                    )}
                </ul>
            </>
        );
    }
};

export default VerticalNavigation;
