import config from "main-config.json";
import "./VouchersPage.css";
import { useTranslation } from "react-i18next";
import BetaVersion from "Views/BetaVersion";
import { Link } from "react-router-dom";

const VouchersPage = () => {
    const { t } = useTranslation();

    return (
        <BetaVersion>
            <div className='row'>
                <div
                    className='voucher_page_image'
                    id={`${config.stores[0].name}_voucher_page_image`}></div>
            </div>
            <section className='content-section text-center'>
                <h1 className='vouchers_title_page'>
                    <span className='underline'>{t("vouchers")}</span>
                </h1>
                <div
                    className='row'
                    style={{ marginBottom: "3rem" }}>
                    <div className='col-lg-6 mx-auto align-self-center'>
                        {config.stores.map((item, index) => (
                            <div key={index}>
                                <h2 className='city_voucher_title'>
                                    {item.name}
                                </h2>
                                <div
                                    className='col-lg-12 align-self-center text-center vouchers_stores_sections'
                                    style={{ marginTop: "2rem" }}>
                                    <Link
                                        to={`/page_with_external_content/gift_vouchers/${item.name}`}
                                        key='gift_vouchers'
                                        className='btn btn-default btn-md'
                                        style={{
                                            margin: "1rem",
                                        }}>
                                        GIFT VOUCHERS
                                    </Link>
                                    <Link
                                        to={`/page_with_external_content/redeem_vouchers/${item.name}`}
                                        key='redeem_vouchers'
                                        className='btn btn-default btn-md'
                                        style={{
                                            margin: "1rem",
                                        }}>
                                        REDEEM VOUCHERS
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </BetaVersion>
    );
};

export default VouchersPage;
