import "./Contact.css";
import { lazy, Suspense } from "react";

export const Contact = () => {
    const BetaVersion = lazy(() => import("Views/BetaVersion"));
    const GoogleMapSection = lazy(() =>
        import("components/Contact/Google/GoogleMap")
    );
    const LocationInfo = lazy(() =>
        import("components/Contact/Location/LocationInfo")
    );
    const ContactForm = lazy(() =>
        import("components/Contact/Form/ContactForm")
    );
    return (
        <Suspense>
            <BetaVersion>
                <GoogleMapSection />
                <section className='content-section text-left'>
                    <div className='col-12 row contact-form-section'>
                        <LocationInfo />
                        <ContactForm />
                    </div>
                </section>
            </BetaVersion>
        </Suspense>
    );
};

export default Contact;
