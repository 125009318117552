import { useState, useEffect } from "react";
import "./navigation.css";
import { getNavigationStyle } from "helpers/Navigation.helpers";
import { ENavLayouts } from "constants/Navigation";
import { navpanel } from "./NavigationLinks";
import HorizontalNavigation from "./HorizontalNavigation";
import VerticalNavigation from "./VerticalNavigation";
import logo from "assets/default/utils/logo.png";
import config from "main-config.json";

/**
 * @returns the navigation menu
 */

const Navigation = () => {
    const defaultLayout = navpanel.layout;
    //console.log(window.history);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        const handleScroll = () =>
            setScrollPosition(document.documentElement.scrollTop);

        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const navigationStyle = getNavigationStyle(
        defaultLayout,
        screenWidth,
        scrollPosition
    );

    return (
        <nav
            id='mainNav'
            className={`${config.stores[0].name}-menu navbar navbar-expand-lg navbar-light`}
            style={navigationStyle}>
            <a href='/'>
                <img
                    loading='lazy'
                    src={logo}
                    id={
                        defaultLayout == ENavLayouts.HORIZONTAL
                            ? "logo"
                            : "vertical-logo"
                    }
                    alt='menu logo'
                />
            </a>
            {defaultLayout == ENavLayouts.HORIZONTAL && (
                <HorizontalNavigation />
            )}
            {defaultLayout == ENavLayouts.VERTICAL && <VerticalNavigation />}
        </nav>
    );
};

export default Navigation;
