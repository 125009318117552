import config from "main-config.json";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { langImages, storeLang } from "helpers/Navigation.helpers";
import { toggleLangDropdown } from "helpers/Navigation.helpers";
import LanguageDropdown from "./languageDropdown";

const DesktopLangDropdown = () => {
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(
        localStorage.getItem("language")
    ); // state variable to hold the selected language
    let lngs = {};
    config.supported_langs.map(
        (key) => (lngs[key] = config.supported_langs[key])
    );

    const switchLang = (lngs) => {
        i18n.changeLanguage(lngs);
        localStorage.setItem("language", lngs);
        setSelectedLang(lngs);
    };

    useEffect(() => {
        storeLang(switchLang);
    }, [config.supported_langs]);

    return (
        <li
            className='nav-item dropdown'
            id='navbarDropdownMenuLink'>
            <div
                className='custom-dropdown-show'
                onClick={toggleLangDropdown}>
                <a
                    className='custom-btn-class dropdown-toggle'
                    role='button'
                    id='dropdownMenuLink'>
                    <LanguageDropdown
                        src={langImages([selectedLang])}
                        content={selectedLang}
                    />
                </a>
                <div className='custom-dropdown-menu'>
                    {config.supported_langs.map((lang) => {
                        return (
                            <a
                                id='lang-links'
                                key={[lang]}
                                className='dropdown-item nav-link js-scroll-trigger'
                                onClick={() => switchLang([lang])}>
                                <LanguageDropdown
                                    src={langImages([lang])}
                                    content={lang}
                                />
                            </a>
                        );
                    })}
                </div>
            </div>
        </li>
    );
};

export default DesktopLangDropdown;
