import "App.css";
import { useEffect, useState } from "react";
import { reloadResources } from "i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "common/css/shared.css";
import "common/fonts/Raleway.css";
import "common/css/grayscale.min.css";
import "common/css/grayscale.css";
import "common/font-awesome/css/font-awesome.min.css";
import { Home } from "pages/Home/Home";
import Room from "pages/Room/Room";
import EventsPage from "pages/Events/Events";
import Contact from "pages/Contacts/Contact";
import BoardGames from "pages/BoardGames/BoardGames";
import FaqPage from "pages/FAQS/FAQS";
import TeamBuildingPage from "pages/TeamBuilding/TeamBuilding";
import BirthdayPartyPage from "pages/BirthdayParty/BirhdayPartyPage";
import PageWithExternalContent from "components/PageWithExternalContent/PageWithExternalContent";
import VouchersPage from "components/VouchersPage/VouchersPage";

// const Context = React.createContext();

const locale = navigator.language;

function App() {
    //-->To Do move into another file
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            reloadResources(localStorage.getItem("language")).then(() =>
                setLoaded(true)
            );
        }
    }, [localStorage, loaded]);

    return (
        <div className='App'>
            <BrowserRouter>
                <Routes>
                    <Route
                        path='/'
                        element={<Home lang={locale} />}
                    />
                    <Route
                        path='/rooms/:room'
                        element={<Room />}
                    />
                    <Route
                        path='/events'
                        element={<EventsPage />}
                    />
                    <Route
                        path='/contact'
                        element={<Contact />}
                    />
                    <Route
                        path='/boardgames'
                        element={<BoardGames />}
                    />
                    <Route
                        path='/faqs'
                        element={<FaqPage />}
                    />
                    <Route
                        path='/teamBuilding'
                        element={<TeamBuildingPage />}
                    />
                    <Route
                        path='/birthdayParty'
                        element={<BirthdayPartyPage />}
                    />
                    <Route
                        path='/page_with_external_content/:type/:storeName'
                        element={<PageWithExternalContent />}
                    />
                    <Route
                        path='/vouchers'
                        element={<VouchersPage />}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
