import { useEffect, useRef, useState } from "react";
import config from "main-config.json";
import BetaVersion from "Views/BetaVersion";
import "./PageWithExternalContent.css";
import { useScript, getScript } from "helpers/PageWithExternalContent.helper";
import { useParams } from "react-router-dom";

const PageWithExternalContent = () => {
    const { type, storeName } = useParams();
    const store = config.stores.find((store) => store.name === storeName);
    const sectionRef = useRef(null);
    const [reloadExecuted, setReloadExecuted] = useState(false);

    const reloadOnce = () => {
        if (
            !reloadExecuted &&
            sectionRef.current &&
            sectionRef.current.children.length === 1
        ) {
            setReloadExecuted(true);
            if (type === "gift_vouchers" || type === "redeem_vouchers") {
                window.location.reload();
            }
        }
    };

    useEffect(() => {
        const scriptUrl = getScript(type, store);
        useScript(scriptUrl, sectionRef);
    }, [type, store]);

    useEffect(() => {
        const intervalId = setInterval(reloadOnce, 500);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <BetaVersion>
            <section
                ref={sectionRef}
                className='content-section'></section>
        </BetaVersion>
    );
};

export default PageWithExternalContent;
