import fb from "assets/default/components/social/fb.svg";
import insta from "assets/default/components/social/insta.svg";
import config from "main-config.json";
import "./social.css";

const Social = () => {
    const socialLinks = config.runtime.pages.home.components.contactButtons.map(
        (item) => item.href
    );

    return (
        <section
            className='text-center'
            id='footer'>
            <a
                href={socialLinks[1]}
                className='facebook-link'
                alt='facebook_page'>
                <img
                    loading='lazy'
                    src={fb}
                    alt='facebook_logo'
                />
            </a>
            <a
                href={socialLinks[2]}
                className='instagram-link'
                alt='instagram_page'>
                <img
                    loading='lazy'
                    src={insta}
                    alt='instagram_logo'
                />
            </a>
        </section>
    );
};

export default Social;
