import config from "main-config.json";
import "../navigation.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {
    langImages,
    storeLang,
    isHorizontal,
    closeNavMenu,
    closeVerticalMenu,
} from "helpers/Navigation.helpers";
import LanguageDropdown from "./languageDropdown";
import PropTypes from "prop-types";

const MobileLangDropdown = ({ closeMenu }) => {
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(
        localStorage.getItem("language")
    ); // state variable to hold the selected language
    let lngs = {};
    const [menuVisible, setMenuVisible] = useState(false);
    config.supported_langs.map(
        (key) => (lngs[key] = config.supported_langs[key])
    );

    const switchLang = (lngs) => {
        i18n.changeLanguage(lngs);
        localStorage.setItem("language", lngs); //stores the language value in the local storage of the user so that the language doesn't change every time the user changes the route
        setSelectedLang(lngs); // update the selected language state
        isHorizontal() ? closeMenu() : closeVerticalMenu();
    };

    useEffect(() => {
        storeLang(switchLang);
    }, [config.supported_langs]);

    const filteredLangMenu = config.supported_langs.filter(
        (lang) => lang != selectedLang
    );

    const handleMenuClick = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        <li
            className='nav-item'
            id={isHorizontal() ? "navbarDropdownMenuLink" : "nav-item"}
            style={{ paddingTop: "0.5%" }}>
            <div className='row justify-content-end'>
                {menuVisible &&
                    filteredLangMenu.map((lang) => {
                        return (
                            <a
                                id={
                                    isHorizontal()
                                        ? "mobile-lang-links"
                                        : "vertical-mobile-lang-links"
                                }
                                key={[lang]}
                                className='nav-link js-scroll-trigger'
                                onClick={() => {
                                    switchLang([lang]), setMenuVisible(null);
                                    isHorizontal()
                                        ? closeNavMenu()
                                        : closeVerticalMenu();
                                }}>
                                <LanguageDropdown
                                    src={langImages([lang])}
                                    content={filteredLangMenu}
                                />
                            </a>
                        );
                    })}
                <a
                    className={
                        isHorizontal() ? "custom-lang-text-btn" : "vertical-btn"
                    }
                    onClick={handleMenuClick}
                    role='button'
                    id='mobileDropdownMenuLink'>
                    <LanguageDropdown
                        src={langImages([selectedLang])}
                        content={selectedLang}
                    />
                </a>
            </div>
        </li>
    );
};

MobileLangDropdown.propTypes = {
    closeMenu: PropTypes.func,
};

export default MobileLangDropdown;
