import React from "react";
import ReactDOM from "react-dom/client";
import "../src/services/translations/i18n";
import "./index.css";
import config from "main-config.json";
import App from "./App";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
const website_title = `${config.stores[0].location.city} - GAME OVER Escape Rooms - ${config.stores[0].location.country}`;
document.getElementById("website_title").innerText = website_title;
const googleTagSrc = config.google_tags?.google_analytics.src;
const googleTagScript = config.google_tags?.google_analytics.script;
document.getElementById("google_analytics_src").src = googleTagSrc;
document.getElementById("google_analytics_script").innerText = googleTagScript;
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
