import "./footer.css";

function getYear() {
    return new Date().getFullYear();
}

const Footer = () => {
    return (
        <div id='footer'>
            <div className='container text-center'>
                <p className='footer-p'>
                    Copyright &copy; {getYear()} &nbsp; ESCAPE GAME OVER. All
                    Rights Reserved.
                </p>
                <p className='second footer-p'>
                    Created With &hearts; By GAME OVER Dev. Team{" "}
                </p>
            </div>
        </div>
    );
};

export default Footer;
