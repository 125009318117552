import config from "main-config.json";
import ImageExtractor from "utils/Extractors/images";

/**
 * @export the enriched scenarios into the Scenarios.jsx component
 */

//const { rooms } = config.runtime.pages;

/**@deprecated */
export let enrichedScenarios = () => {
    if (config.store) {
        return config.runtime.pages.rooms.map((item) => {
            return {
                ...item,
                images: {
                    desktop: ImageExtractor(
                        `scenarios/desktop/${item.images.jpeg}`
                    ),
                    mobile: ImageExtractor(
                        `scenarios/mobile/${item.images.jpeg}`
                    ),
                    svg: ImageExtractor(`default/svgs/${item.images.svg}`),
                },
            };
        });
    } else if (config.stores) {
        //We need to figure out in which section we need to display the rooms of each store
        return config.stores.flatMap((store) => {
            return store.runtime.pages.rooms.map((item) => {
                return {
                    ...item,
                    images: {
                        desktop: ImageExtractor(
                            `scenarios/desktop/${item.images.jpeg}`
                        ),
                        mobile: ImageExtractor(
                            `scenarios/mobile/${item.images.jpeg}`
                        ),
                        svg: ImageExtractor(`default/svgs/${item.images.svg}`),
                    },
                };
            });
        });
    }
};

/**
 * @name mapDualStoreScenarios
 */
export let mapDualStoreScenarios = () => {
    return config.stores.map((store) => {
        const categories = [
            ...new Set(
                store.runtime.pages.rooms.flatMap((room) =>
                    room.category.split(" / ")
                )
            ),
        ];

        return {
            name: store.name,
            title: store.runtime.pages.home.components.location.infos.title,
            address: store.runtime.pages.home.components.location.infos.address,
            filters: [
                {
                    id: "0",
                    value: "all",
                },
                ...categories.map((category, index) => ({
                    id: (index + 1).toString(),
                    value: category,
                })),
            ],
            scenarios: store.runtime.pages.home.components.scenarios.map(
                (item) => {
                    const room = store.runtime.pages.rooms.find(
                        (roomItem) => roomItem.id === item.href
                    );
                    return {
                        ...item,
                        roomCategory: room.category,
                        images: {
                            desktop: ImageExtractor(
                                `scenarios/desktop/${item.images.jpeg}`
                            ),
                            mobile: ImageExtractor(
                                `scenarios/mobile/${item.images.jpeg}`
                            ),
                            svg: ImageExtractor(
                                `default/svgs/${item.images.svg}`
                            ),
                        },
                    };
                }
            ),
        };
    });
};
